import { Controller } from '@hotwired/stimulus'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

export default class extends Controller {

  static targets = [
    'map'
  ]

  static values = {
    projects: Array
  }

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
    console.log(this.projectsValue)
  }

  initializeMap() {
    this.map()
    this.markers()
  }

  map() {
    if (this._map == undefined) {
      const options = require('./map-styles.json')
      this._map = new google.maps.Map(this.mapTarget, options)
    }
    return this._map
  }

  async markers() {

    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary(
      "marker",
    )

    const markerItems = this.projectsValue.map((project) => {
      const pinGlyph = new PinElement({
        background: '#059669',
        borderColor: 'white',
        glyph: project.country,
        glyphColor: "white",
      })
      const marker = new AdvancedMarkerElement({
        position: { 
          lat: Number(project.lat),
          lng: Number(project.lng)
        },
        content: pinGlyph.element,
      })
      marker.addListener('click', async () => {
        const modalFrame = document.getElementById('modal_frame')
        modalFrame.src = `/projects/${project.id}`
      })
      return marker
    })

    new MarkerClusterer({ markers: markerItems, map: this.map() })
    let bounds = new google.maps.LatLngBounds()

    this.projectsValue.map((project) => {
      bounds.extend({ lat: Number(project.lat), lng: Number(project.lng) })
    })
    this.map().fitBounds(bounds)

  }

}
